import {React, useState, useEffect, lazy, Suspense, useContext } from 'react';
import api from './api'
import './App.css';

// Packages
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { CompanyContext, ConfigContext, ConfigLoadedContext, SeoContext, LangContext } from './context/context';
import Helmet from 'react-helmet';

import ScrollToTop from './helpers/scrollToTop.js'

// Components
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import Favicon from './components/Favicon/Favicon';
import Loading from './components/Loading/Loading';

// Views
//import Development from './views/Development/Development';

const Home = lazy(() => import('./views/Home/Home'));
const Wohnen = lazy(() => import('./views/Wohnen/Wohnen'));
const HausUndUmgebung = lazy(() => import('./views/HausUndUmgebung/HausUndUmgebung'));
const Veranstaltungen = lazy(() => import('./views/Veranstaltungen/Veranstaltungen'));
const Kontakt = lazy(() => import('./views/Kontakt/Kontakt'));
const UeberUns = lazy(() => import('./views/UeberUns/UeberUns'));
const Jobs = lazy(() => import('./views/Jobs/Jobs'));
const OffeneStellen = lazy(() => import('./views/OffeneStellen/OffeneStellen'));
const Schnellbewerbung = lazy(() => import('./views/Schnellbewerbung/Schnellbewerbung'));
const Ausbildung = lazy(() => import('./views/Ausbildung/Ausbildung'));
const PflegeBetreuung = lazy(() => import('./views/PflegeBetreuung/PflegeBetreuung'));
const Gastronomie = lazy(() => import('./views/Gastronomie/Gastronomie'));
const Stelleninserat = lazy(() => import('./views/Stelleninserat/Stelleninserat'));
const UnsereHaeuser = lazy(() => import('./views/UnsereHaeuser/UnsereHaeuser'));
const Haus = lazy(() => import('./views/Haus/Haus'));
const DynamicPage = lazy(() => import('./views/DynamicPage/DynamicPage'));
const Datenschutz = lazy(() => import('./views/Datenschutz/Datenschutz'));
const Impressum = lazy(() => import('./views/Impressum/Impressum'));
const Auth = lazy(() => import('./views/Auth/Auth'));
const Intern = lazy(() => import('./views/Intern/Intern'));
const MailSignatur = lazy(() => import('./views/MailSignatur/MailSignatur'));

const NotFound = lazy(() => import('./views/NotFound/NotFound'));

function App() {
  const [configContext, setConfigContext] = useState(ConfigContext);
  const [lang, setLang] = useState('');
  const [configLoadedContext, setConfigLoadedContext] = useState(ConfigLoadedContext);
  const [seoContext, setSeoContext] = useState(SeoContext);

  const navigate = useNavigate();
  const location = useLocation();

  let company;
  let root = document.documentElement;

// Check hostname and set CompanyContext

switch (window.location.hostname) {
  case 'averecura.ch':
  case 'www.averecura.ch':
  case 'averecura.digital':
    company = 'digallo';
    root.style.setProperty('--color-primary', "#9BB082");
    root.style.setProperty('--color-primary-transparency', "rgba(155, 176, 130, 0.6)");
    root.style.setProperty('--color-primary-hover', "#afc09b");
    root.style.setProperty('--color-secondary', "#FDC333");
    root.style.setProperty('--color-secondary-hover', "#b18924");
    break;

  case 'zumipark.ch':
  case 'www.zumipark.ch':
    company = 'zumipark';
    root.style.setProperty('--color-primary', "#952744");
    root.style.setProperty('--color-primary-transparency', "rgba(149, 39, 68, 0.6)");
    root.style.setProperty('--color-primary-hover', "#aa5269");
    root.style.setProperty('--color-secondary', "#CA93A2");
    root.style.setProperty('--color-secondary-hover', "#681b30");
    break;

  case 'tabor-wald.ch':
  case 'www.tabor-wald.ch':
  case 'taborwald.ch':
  case'www.taborwald.ch':
    company = 'tabor';
    root.style.setProperty('--color-primary', "#005748");
    root.style.setProperty('--color-primary-transparency', "rgba(0, 87, 72, 0.6)");
    root.style.setProperty('--color-primary-hover', "#33796d");
    root.style.setProperty('--color-secondary', "#99BCB6");
    root.style.setProperty('--color-secondary-hover', "#7a9692");
    break;

  case 'rosengarten-gossau-zh.ch':
  case 'www.rosengarten-gossau-zh.ch':
    company = 'rosengarten';
    root.style.setProperty('--color-primary', "#CF2D2D");
    root.style.setProperty('--color-primary-transparency', "rgba(207, 45, 45, 0.6)");
    root.style.setProperty('--color-primary-hover', "#d95757");
    root.style.setProperty('--color-secondary', "#E79696");
    root.style.setProperty('--color-secondary-hover', "#911f1f");
    break;
    
  case 'kreuzstift.ch':
  case 'www.kreuzstift.ch':
    company = 'kreuzstift';
    root.style.setProperty('--color-primary', "#F8A911");
    root.style.setProperty('--color-primary-transparency', "rgba(248, 169, 17, 0.6)");
    root.style.setProperty('--color-primary-hover', "#f9ba41");
    root.style.setProperty('--color-secondary', "#FCDDA0");
    root.style.setProperty('--color-secondary-hover', "#ae760c");
    break;

  case 'hauswaeckerling.ch':
  case 'www.hauswaeckerling.ch':
    company = 'waeckerling';
    root.style.setProperty('--color-primary', "#E8561F");
    root.style.setProperty('--color-primary-transparency', "rgba(232, 86, 31, 0.6)");
    root.style.setProperty('--color-primary-hover', "#ed784c");
    root.style.setProperty('--color-secondary', "#F4AB8F");
    root.style.setProperty('--color-secondary-hover', "#a23c16");
    break;

  case 'brunisberg.ch':
  case 'www.brunisberg.ch':
    company = 'brunisberg';
    root.style.setProperty('--color-primary', "#89693e");
    root.style.setProperty('--color-primary-transparency', "rgba(137, 105, 62, 0.6)");
    root.style.setProperty('--color-primary-hover', "#a18765");
    root.style.setProperty('--color-secondary', "#c4b49f");
    root.style.setProperty('--color-secondary-hover', "#604a2b");
    break;

  {/*averecura.digital*/}

  case 'holding.averecura.digital':
    company = 'digallo';
    root.style.setProperty('--color-primary', "#9BB082");
    root.style.setProperty('--color-primary-transparency', "rgba(155, 176, 130, 0.6)");
    root.style.setProperty('--color-primary-hover', "#afc09b");
    root.style.setProperty('--color-secondary', "#FDC333");
    root.style.setProperty('--color-secondary-hover', "#b18924");
    break;

  case 'zumipark.averecura.digital':
    company = 'zumipark';
    root.style.setProperty('--color-primary', "#952744");
    root.style.setProperty('--color-primary-transparency', "rgba(149, 39, 68, 0.6)");
    root.style.setProperty('--color-primary-hover', "#aa5269");
    root.style.setProperty('--color-secondary', "#CA93A2");
    root.style.setProperty('--color-secondary-hover', "#681b30");
    break;

  case 'tabor.averecura.digital':
    company = 'tabor';
    root.style.setProperty('--color-primary', "#005748");
    root.style.setProperty('--color-primary-transparency', "rgba(0, 87, 72, 0.6)");
    root.style.setProperty('--color-primary-hover', "#33796d");
    root.style.setProperty('--color-secondary', "#99BCB6");
    root.style.setProperty('--color-secondary-hover', "#7a9692");
    break;

  case 'rosengarten.averecura.digital':
    company = 'rosengarten';
    root.style.setProperty('--color-primary', "#CF2D2D");
    root.style.setProperty('--color-primary-transparency', "rgba(207, 45, 45, 0.6)");
    root.style.setProperty('--color-primary-hover', "#d95757");
    root.style.setProperty('--color-secondary', "#E79696");
    root.style.setProperty('--color-secondary-hover', "#911f1f");
    break;

  case 'kreuzstift.averecura.digital':
    company = 'kreuzstift';
    root.style.setProperty('--color-primary', "#F8A911");
    root.style.setProperty('--color-primary-transparency', "rgba(248, 169, 17, 0.6)");
    root.style.setProperty('--color-primary-hover', "#f9ba41");
    root.style.setProperty('--color-secondary', "#FCDDA0");
    root.style.setProperty('--color-secondary-hover', "#ae760c");
    break;

  case 'waeckerling.averecura.digital':
    company = 'waeckerling';
    root.style.setProperty('--color-primary', "#E8561F");
    root.style.setProperty('--color-primary-transparency', "rgba(232, 86, 31, 0.6)");
    root.style.setProperty('--color-primary-hover', "#ed784c");
    root.style.setProperty('--color-secondary', "#F4AB8F");
    root.style.setProperty('--color-secondary-hover', "#a23c16");
    break;

  case 'brunisberg.averecura.digital':
    company = 'brunisberg';
    root.style.setProperty('--color-primary', "#89693e");
    root.style.setProperty('--color-primary-transparency', "rgba(137, 105, 62, 0.6)");
    root.style.setProperty('--color-primary-hover', "#a18765");
    root.style.setProperty('--color-secondary', "#c4b49f");
    root.style.setProperty('--color-secondary-hover', "#604a2b");
    break;

  case 'rivabella.averecura.digital':
    company = 'rivabella';
    root.style.setProperty('--color-primary', "#32a2db");
    root.style.setProperty('--color-primary-transparency', "rgba(50, 162, 219, 0.6)");
    root.style.setProperty('--color-primary-hover', "#5bb5e2");
    root.style.setProperty('--color-secondary', "#a0dfff");
    root.style.setProperty('--color-secondary-hover', "#237199");
    break;  

  case 'impuls.averecura.digital':
    company = 'impuls';
    root.style.setProperty('--color-primary', "#E67473");
    root.style.setProperty('--color-primary-transparency', "rgba(230, 116, 115, 0.6)");
    root.style.setProperty('--color-primary-hover', "#b85d5c");
    root.style.setProperty('--color-secondary', "#eb908f");
    root.style.setProperty('--color-secondary-hover', "#8a4645");
    break; 

  case 'geeren.averecura.digital':
    company = 'geeren';
    root.style.setProperty('--color-primary', "#27357A");
    root.style.setProperty('--color-primary-transparency', "rgba(39, 53, 122, 0.6)");
    root.style.setProperty('--color-primary-hover', "#525d95");
    root.style.setProperty('--color-secondary', "#6777B2");
    root.style.setProperty('--color-secondary-hover', "#3e476b");
    break; 

  case 'bauma.averecura.digital':
    company = 'bauma';
    root.style.setProperty('--color-primary', "#216A2F");
    root.style.setProperty('--color-primary-transparency', "rgba(33, 106, 47, 0.6)");
    root.style.setProperty('--color-primary-hover', "#4d8859");
    root.style.setProperty('--color-secondary', "#66966E");
    root.style.setProperty('--color-secondary-hover', "#3d5a42");
    break; 

  default:
    company = 'rivabella';
    root.style.setProperty('--color-primary', "#32a2db");
    root.style.setProperty('--color-primary-transparency', "rgba(50, 162, 219, 0.6)");
    root.style.setProperty('--color-primary-hover', "#5bb5e2");
    root.style.setProperty('--color-secondary', "#a0dfff");
    root.style.setProperty('--color-secondary-hover', "#237199");
    break;  
  }

let routes = (
          <Routes>
            <Route path="/" element={<Home/>} />
            {/*<Route path="/dev" element={<Development />} />*/}
            <Route path="/wohnen">
              <Route index element={<Wohnen />} />
              <Route path="haus-umgebung" element={<HausUndUmgebung/>} />
              <Route path="veranstaltungen" element={<Veranstaltungen />} />
            </Route>
            <Route path="pflege-betreuung" element={<PflegeBetreuung />} />
            <Route path="gastronomie" element={<Gastronomie />}>
              <Route index element={<Home />} />
            </Route>
            <Route path="/ueber-uns" element={<UeberUns />} />
            <Route path="/jobs">
              <Route index element={<Jobs />} />
              <Route path="offene-stellen" element={<OffeneStellen />} />
              <Route path="offene-stellen/:id" element={<Stelleninserat />} />
              <Route path="ausbildung" element={<Ausbildung />} />
              <Route path="schnellbewerbung" element={<Schnellbewerbung />} />
            </Route>
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />

            {/* DGG Routes */}
            <Route path="/unsere-haeuser" element={<UnsereHaeuser />} />
            <Route path="/unsere-haeuser/:haus" element={<Haus />} />

            {/* Seiten */}
            <Route path="/s/:seite" element={<DynamicPage />} />

            <Route path='/mail-signatur' element={<MailSignatur/>} />

            {/* Auth Pages */}
            <Route path="/auth" element={<Auth/>} />
            <Route path='/intern' element={<Intern/>} />

            {/* Not Found Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
  )

  if (company === "rivabella") {
    routes = (
      <Routes>
        <Route path="/:lang">
              <Route path="" element={<Home/>} />
              <Route path="wohnen">
                <Route index element={<Wohnen />} />
                <Route path="haus-umgebung" element={<HausUndUmgebung/>} />
                <Route path="veranstaltungen" element={<Veranstaltungen />} />
              </Route>
              <Route path="pflege-betreuung" element={<PflegeBetreuung />} />
              <Route path="gastronomie" element={<Gastronomie />}>
                <Route index element={<Home />} />
              </Route>
              <Route path="ueber-uns" element={<UeberUns />} />
              <Route path="jobs">
                <Route index element={<Jobs />} />
                <Route path="offene-stellen" element={<OffeneStellen />} />
                <Route path="offene-stellen/:id" element={<Stelleninserat />} />
                <Route path="ausbildung" element={<Ausbildung />} />
                <Route path="schnellbewerbung" element={<Schnellbewerbung />} />
              </Route>
              <Route path="kontakt" element={<Kontakt />} />
              <Route path="datenschutz" element={<Datenschutz />} />
              <Route path="impressum" element={<Impressum />} />

              {/* DGG Routes */}
              <Route path="unsere-haeuser" element={<UnsereHaeuser />} />
              <Route path="unsere-haeuser/:haus" element={<Haus />} />

              {/* Seiten */}
              <Route path="s/:seite" element={<DynamicPage />} />

              <Route exact path='mail-signatur' element={<MailSignatur/>} />

              {/* Auth Pages */}
              <Route path="auth" element={<Auth/>} />
              <Route exact path='intern' element={<Intern/>} />

              {/* Not Found Route */}
              <Route path="*" element={<NotFound />} /> 
            </Route>
      </Routes>
      )}

useEffect(() => {
  /* Multilangage aktivieren */
  if (company === "rivabella") {
    const langMap = {
      de: 'de-CH',
      en: 'en',
      it: 'it-CH',
      fr: 'fr-CH'
    };

    const path = window.location.pathname;
    console.log(path)

    // Check if the path has a language code
    const langCode = path.split('/')[1];
    // console.log(langCode)


    if (langCode && langMap[langCode] && lang === "") {
      setLang(langMap[langCode]);
    } 
    else if (lang !== langMap[langCode] & lang !== "") {
      // Update the URL if the context language doesn't match the URL language
      
      const newPath = `/${lang.split('-')[0]}${path.slice(langCode.length + 1)}`;
      
      if (newPath !== path) {
        navigate(newPath, { replace: true });
      };
    } else {
      if (lang === "") {
        const newPath = `/de${window.location.pathname}`;
        console.log(newPath)
        console.log(path)
        if (newPath !== path) {
          setLang('de-CH')
          navigate(newPath, { replace: true });
        }
      }
    }
  }
    

    const fetchData = async () => {
      const result = await api.get("konfiguration-"+company+"?populate=deep&locale="+lang);
      const config = (result.data.data.attributes);
      console.log(lang)
      setConfigContext(config);
      console.log(config)
      setConfigLoadedContext(true)
    };

    fetchData();

  }, [company, lang, setLang, navigate, location.pathname]);
  
  
  return (
    <div className="App">
      <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      <Helmet titleTemplate={"%s • "+configContext.Name} >
        <title>Home</title>
        <link href={window.location.href.toLowerCase()} rel="canonical" />  
        {/*<meta http-equiv="Content-Security-Policy" content="default-src 'self'; img-src https://*; child-src 'none';"/>*/}
      </Helmet>

      <Favicon company={company} />
      <CompanyContext.Provider value={company}>
      <ConfigLoadedContext.Provider value={configLoadedContext}>
      <LangContext.Provider value={[lang, setLang]}>
      <ConfigContext.Provider value={configContext}>
      <SeoContext.Provider value={[seoContext, setSeoContext]}>
        <Helmet>
          <title>{seoContext?.metaTitle}</title>
          <meta name="description" content={seoContext?.metaDescription} />
          <meta name="keywords" content={seoContext?.metaKeywords} />
          <meta property="og:image" content={seoContext?.metaImage?.data?.attributes?.formats?.gallery?.url} />
          <meta name="theme-color" content={"#"+configContext.mainColor} />
        </Helmet>
        

        <Navigation/>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          {routes}
        </Suspense>
        <Footer />
      </SeoContext.Provider>
      </ConfigContext.Provider>
      </LangContext.Provider>
      </ConfigLoadedContext.Provider>
      </CompanyContext.Provider>
    </div>
  );
}

export default App;