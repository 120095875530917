import {React, useContext } from 'react';
import PropTypes from 'prop-types';

import { ConfigContext } from '../../context/context'

import {  BsQuestion,
          BsInstagram, 
          BsLinkedin, 
          BsFacebook, 
          BsTiktok, 
          BsHouse,
          BsArrowRight, 
          BsPerson, 
          BsSuitHeart, 
          BsDownload, 
          BsList, 
          BsEnvelope, 
          BsEnvelopeFill, 
          BsTelephone, 
          BsSend, 
          BsHourglassTop, 
          BsHourglassSplit, 
          BsInfoCircle,
          BsGeoAltFill,
          BsBoxArrowInRight,
          BsBoxArrowLeft,
          BsPrinter,
          BsEnvelopeAt,
          BsPhoneVibrate,
          BsBusFront,
          BsCarFrontFill,
          BsGlobe2,
          BsCopy,
          BsCheck2,
        } from "react-icons/bs";

/* import styles from './Icon.module.css' */

export default function Icon({ icon, size, color }) {
  const config = useContext(ConfigContext);

  let iconColor = config.mainColor

  if (color) {
    iconColor = color;
  };

let icons = <BsQuestion />

  switch (icon) {
    case 'linkedin':
      icons = <BsLinkedin color={iconColor} size={size}/>
      break;
    case 'tiktok':
      icons = <BsTiktok color={iconColor} size={size}/>
      break;
    case 'facebook':
      icons = <BsFacebook color={iconColor} size={size}/>
      break;
    case 'instagram':
      icons = <BsInstagram color={iconColor} size={size}/>
      break;
    case 'house':
      icons = <BsHouse color={iconColor} size={size}/>
      break;
    case 'arrowRight':
      icons = <BsArrowRight color={iconColor} size={size}/>
      break;
    case 'person':
      icons = <BsPerson color={iconColor} size={size}/>
      break;
    case 'heart':
      icons = <BsSuitHeart color={iconColor} size={size}/>
      break;
    case 'download':
      icons = <BsDownload color={iconColor} size={size}/>
      break;
    case 'list':
      icons = <BsList color={iconColor} size={size}/>
      break;
    case 'email':
      icons = <BsEnvelope color={iconColor} size={size}/>
      break;
    case 'emailFill':
      icons = <BsEnvelopeFill color={iconColor} size={size}/>
      break;
    case 'phone':
      icons = <BsTelephone color={iconColor} size={size}/>
      break;
    case 'send':
      icons = <BsSend color={iconColor} size={size}/>
      break;
    case 'hourglassTop':
      icons = <BsHourglassTop color={iconColor} size={size}/>
      break;
    case 'hourglassSplit':
      icons = <BsHourglassSplit color={iconColor} size={size}/>
      break;
    case 'infoCircle':
      icons = <BsInfoCircle color={iconColor} size={size}/>
      break;
    case 'geoPin':
      icons = <BsGeoAltFill color={iconColor} size={size}/>
      break;
    case 'boxArrowInRight':
      icons = <BsBoxArrowInRight color={iconColor} size={size}/>
      break;
    case 'boxArrowLeft':
      icons = <BsBoxArrowLeft color={iconColor} size={size}/>
      break;
    case 'printer':
      icons = <BsPrinter color={iconColor} size={size}/>
      break;
    case 'envelopeAt':
      icons = <BsEnvelopeAt color={iconColor} size={size}/>
      break;
    case 'mobile':
      icons = <BsPhoneVibrate color={iconColor} size={size}/>
      break;
    case 'bus':
      icons = <BsBusFront color={iconColor} size={size}/>
      break;
    case 'car':
      icons = <BsCarFrontFill color={iconColor} size={size}/>
      break;
    case 'web':
      icons = <BsGlobe2 color={iconColor} size={size}/>
      break;
    case 'copy':
      icons = <BsCopy color={iconColor} size={size}/>
      break;
    case 'check':
      icons = <BsCheck2 color={iconColor} size={size}/>
      break;
    default:
      icons = <BsArrowRight color={iconColor} size={size}/>
    }


  return (
  	icons
  );
}


Icon.propTypes = {
    size: PropTypes.string,
    icon: PropTypes.oneOf(['linkedin', 'tiktok', 'facebook', 'instagram', 'house', 'arrowRight', 'person', 'heart', 'download', 'list', 'email', 'phone', 'send', 'hourglassTop', 'hourglassSplit', 'emailFill', 'infoCircle', 'geoPin', 'boxArrowInRight', 'boxArrowLeft', 'printer', 'envelopeAt', 'mobile', 'bus', 'car', 'web' ]),
    /** Wenn nicht überschrieben, wird standardmässig 'mainColor' aus der Config verwendet. */
    color: PropTypes.string,  
  }