// export const API = "https://diga-strapi-943yo.ondigitalocean.app/api";

import axios from 'axios';

export default axios.create({
  baseURL: `https://cms.averecura.digital/api/`,
  headers: {
      'Authorization': 'Bearer ' + "3dd607f7660a598dfa2cb5bf6afe717a820f9aa40e75ec455b42ca3bb677fc43e796b63465beb5bb936e4e0c0af7415f17712beb7def0bc4a61f4ec1e5eaae43da1faf85c864cd3b7c29fd6c2e4e737f18e76e086752096055708309baeb97877711d2ff009101d6546cd19f780dfe9f200b4b6357396541de38ba0ac6577d9f"
    }
});


